<template>
  <v-dialog
    :value="value"
    transition="dialog-bottom-transition"
    max-width="600"
    @input="(val) => $emit('input', val)"
    style="z-index: 500"
  >
    <section class="container__bg p-5">
      <input-field light label="Space Name" v-model="name" class="mb-5" />

      <div class="text-neutral-dark strong-header-text font-weight-300 mb-3">
        Space Type
      </div>
      <v-select
        class="room-picker"
        :items="types"
        item-text="name"
        item-value="value"
        v-model="type"
        outlined
        dense
      />

      <v-btn class="d-block ms-auto" color="#071a36" dark @click="add"
        >Add</v-btn
      >
    </section>
  </v-dialog>
</template>

<script>
import InputField from "./InputField.vue";

export default {
  name: "AddRoomModal",
  components: {
    InputField,
  },
  props: {
    value: Boolean,
  },
  data() {
    return { name: "", type: null, types: this.$store.state.room.roomTypes };
  },
  methods: {
    add() {
      this.$store.dispatch("room/addRoom", {
        name: this.name,
        type: this.type,
      });
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &__bg {
    background-color: #fff;
  }
}

.room-picker {
  .v-select__selection {
    font-size: 1.4rem;
    color: var(--primary);
    line-height: 1.1;
  }

  .v-input__slot {
    border: 1px solid var(--blue-1) !important;
    border-radius: 10px !important;
    min-height: 0;
  }

  .v-text-field--outlined fieldset {
    border: 1px solid var(--blue-1) !important;
  }
}
</style>
