<template>
  <div>
    <div
      :class="{ 'text-muted-light': !light, 'text-neutral-dark': light }"
      class="strong-header-text font-weight-300 mb-4 room_type_name"
    >
      {{ label }}
    </div>
    <input
      class="input-field bg-neutral border-blue-1 rounded-borders-8 ps-2"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <div class="text-dark-grey subtitle-text font-weight-200 mt-1">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: "InputField",
  props: {
    label: String,
    subtitle: String,
    value: String,
    light: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.room_type_name {
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.input-field {
  height: 40px;
  border-radius: 4px;
  margin-bottom: 6px;
  padding: 0 0.5rem;
  font-size: 1.5rem;

  width: 100%;
}
</style>
